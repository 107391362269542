<template>
  <v-card v-if="!isLoadingTable">
    <DataTablePagination
      :page="page"
      :search="search"
      :total-items="totalItems"
      :headers="headers"
      :items="users"
      :total-pages.sync="totalPages"
      :filters="filters"
      :is-loading="isLoadingData"
      title="User"
      subtitle="Halaman untuk menambahkan data User"
      add-btn="Tambah Data"
      :actions-row="['delete']"
      @delete-item="confirmDestroy"
      @edit-item="showFormEdit"
      @add-item="showFormAdd"
      @change-page="paginationHandler"
      @live-search="searchHandler"
      @reset-filter="resetFilter"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template
        v-if="dialog == 'add'"
        v-slot:header
      >
        Tambah User
      </template>
      <template
        v-else
        v-slot:header
      >
        Edit User
      </template>
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="user.role"
                label="Role"
                item-value="role"
                :items="roles"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <template v-if="user.role == 'student'">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.nis"
                  label="NIS"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.nisn"
                  label="NISN"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.nik_ktp"
                  label="NIK"
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </div>
            </v-col>
          </template>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-if="user.role == 'school'"
                v-model="user.npsn"
                label="NPSN"
                dense
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="user.name"
                label="Nama"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="user.username"
                label="Username"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="user.email"
                label="Email"
                dense
                type="email"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <template v-if="user.role == 'student'">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <v-autocomplete
                v-model="user.gender"
                label="Jenis Kelamin"
                :items="genders"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="user.religion"
                  label="Agama"
                  :items="religions"
                  dense
                  outlined
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="4"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.ethnic_group"
                  label="Suku"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
          </template>
          <template v-if="user.role == 'student'">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.place_of_birth"
                  label="Tempat Lahir"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.weight"
                  label="Berat Badan (Kg)"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-autocomplete
                  v-model="user.blood_type"
                  :items="blood_types"
                  label="Golongan Darah"
                  dense
                  outlined
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.date_of_birth"
                  label="Tanggal Lahir"
                  dense
                  type="date"
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.height"
                  label="Tinggi Badan (Cm)"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.hobby"
                  label="Hobi"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
          </template>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-if="user.role == 'student'"
                v-model="user.disease_history"
                label="Riwayat Penyakit"
                outlined
                dense
              >
              </v-textarea>
            </div>
          </v-col>
          <template v-if="user.role == 'student'">
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.transport"
                  label="Mode Transportasi"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="user.home_phone"
                  label="Telepon Rumah"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-if="user.role == 'student'"
                  v-model="user.home_distance"
                  label="Jarak dari rumah ke sekolah (KM)"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-if="user.role == 'student'"
                  v-model="user.phone"
                  label="Nomor Telepon"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
          </template>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-if="user.role == 'company'"
                v-model="user.phone"
                label="Nomor Telepon"
                dense
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-if="user.role == 'school'"
                v-model="user.jenjang"
                label="Jenjang"
                item-value="level"
                :items="levels"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-if="user.role == 'school'"
                v-model="user.tahun_ajaran"
                label="Tahun Ajaran"
                item-value="uuid"
                :items="years"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-if="user.role == 'school'"
                v-model="user.semester"
                label="Semester"
                item-value="uuid"
                :items="semester"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="isLoadingButton"
          @click="add()"
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="update(user.uuid)"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @close="modalDialog = false"
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
    >
      <template v-slot:header>
        Delete Confirm
      </template>
      <template v-slot:body>
        Are you sure you want to delete this data?
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
          :disabled="isLoadingButton"
          :loading="isLoadingButton"
          @click="destroy(user.uuid)"
        >
          Delete
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @close="confirmDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </ConfirmDialog>
  </v-card>
  <v-skeleton-loader
    v-else
    v-bind="attrs"
    type="table"
  >
  </v-skeleton-loader>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'
import ModalDialog from '../components/ModalDialog.vue'
import ConfirmDialog from '../components/ConfirmDialog.vue'
// eslint-disable-next-line object-curly-newline
import { mdiMagnify, mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'

export default {
  name: 'User',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        type: {},
      },
      width: '1080',
      isLoadingTable: false,
      isLoadingButton: false,
      isLoadingData: false,
      search: '',
      modalDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      deleteMessage: false,
      updateMessage: false,
      page: 1,
      totalPages: 0,
      totalItems: 0,
      companies: [],
      users: [],
      user: {
        role: '',
        name: '',
        username: '',
        email: '',
        npsn: '',
        jenjang: '',
        tahun_ajaran: '',
        logo: null,
        nis: '',
        nisn: '',
        nama_lengkap: '',
        nama_panggilan: '',
        nik_ktp: '',
        gender: '',
        religion: '',
        ethnic_group: '',
        date_of_birth: '',
        place_of_birth: '',
        weight: '',
        height: '',
        blood_type: '',
        hobby: '',
        disease_history: '',
        transport: '',
        home_distance: '',
        home_phone: '',
        phone: '',
      },
      roles: ['student', 'school', 'company'],
      levels: ['SD', 'SMP', 'SMA', 'SMK'],
      semester: ['Genap', 'Ganjil'],
      genders: ['Laki-Laki', 'Perempuan'],
      religions: ['Islam', 'Kristen', 'Hindu', 'Budha', 'Konghucu'],
      years: ['2020/2021', '2021/2022', '2022/2023'],
      blood_types: ['A', 'AB', 'B', 'O'],
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
      filters: [],
      filterQuery: {
        user: '',
      },
      dialog: 'add',
      confirmDialog: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.list(this.filterQuery)
      },
    },
  },
  mounted() {
    this.isLoadingTable = true
    this.list()
    this.listCompany()
    this.isLoadingTable = false
  },
  methods: {
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async list(params = this.filterQuery) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.$options.name, { ...params, page: this.page }).then(
        ({ data }) => {
          this.users = data.data.map((users, index) => ({
            ...users,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async listCompany() {
      await this.$services.ApiServices.list('company', {}).then(
        ({ data }) => {
          this.companies = data.data
        },
        err => console.error(err),
      )
    },
    resetForm() {
      this.user = {
        role: '',
        name: '',
        username: '',
        email: '',
        npsn: '',
        jenjang: '',
        tahun_ajaran: '',
        logo: null,
        nis: '',
        nisn: '',
        nama_lengkap: '',
        nama_panggilan: '',
        nik_ktp: '',
        gender: '',
        religion: '',
        ethnic_group: '',
        date_of_birth: '',
        place_of_birth: '',
        weight: '',
        height: '',
        blood_type: '',
        hobby: '',
        disease_history: '',
        transport: '',
        home_distance: '',
        home_phone: '',
        phone: '',
      }
    },

    showFormAdd() {
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      await this.$services.UserServices.register(this.user).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.list()
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.resetForm()
      this.modalDialog = false
    },

    async showFormEdit(uuid) {
      this.$services.ApiServices.get(this.$options.name, uuid).then(
        ({ data }) => {
          this.dialog = 'edit'
          this.modalDialog = true
          this.user = data.data
          this.user.province = {
            name: this.user.province,
            province_uuid: this.user.province_uuid,
          }
        },
        err => console.error(err),
      )
    },

    async edit(uuid) {
      await this.$services.ApiServices.get(this.$options.name, uuid).then(
        ({ data }) => {
          this.user = data.data
        },
        err => console.error(err),
      )
      this.editFormDialog = true
    },
    async update(uuid) {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.$options.name, this.user, uuid).then(
        res => {
          this.list()
          this.resetForm()
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.editFormDialog = false
    },
    confirmDestroy(uuid) {
      this.confirmDeleteDialog = true
      this.user.uuid = uuid
    },

    async destroy(uuid) {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.$options.name, uuid).then(
        res => {
          this.list()
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.deleteMessage = true
      this.confirmDeleteDialog = false
    },
    getColor(status) {
      if (status === 'active') return 'v-chip-light-bg primary--text'

      return 'v-chip-light-bg error--text'
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    searchHandler(user) {
      this.filterQuery.user = user
      this.page = 1
      this.list(this.filterQuery)
    },

    resetFilter() {
      this.filterQuery = {
        user: '',
      }

      this.list()
    },
  },
}
</script>

<style>
</style>
